<script>
import {email, minLength, required, sameAs} from "vuelidate/lib/validators";
import FlagButton from '@/components/FlagButton.vue';

import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {useUserStore} from "@/store/user";
import {logoHelper} from "@/helpers/logo-helper";
import InformationPanelRender from "@/components/account/information-panel-render.vue";

export default {
  components: {InformationPanelRender, FlagButton},

  data() {
    return {
      captchaKey: "",
      account: {
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        referralUserId: ""
      },

      loadedCode: false,
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,

      registrationAccess: null
    };
  },

  validations: {
    account: {
      email: {required, email},
      password: {
        required,
        minLength: minLength(8),
        containsUppercase: function (value) {
          return /[A-Z]/.test(value)
        },
        containsLowercase: function (value) {
          return /[a-z]/.test(value)
        },
        containsNumber: function (value) {
          return /[0-9]/.test(value)
        },
        containsSpecial: function (value) {
          return /\W/.test(value)
        }
      },
      confirmPassword: {required, sameAsPassword: sameAs('password')},
      firstName: {required},
      lastName: {required},
      referralUserId: {
        isUUID: function (value) {
          if (!value) {
            return true
          }

          return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(value)
        }
      }
    }
  },

  async created() {
    await this.loadRegistrationAccess()
    document.body.classList.add("auth-body-bg");

    const id = this.$route.params.id;
    if (!id) {
      return;
    }

    this.account.referralUserId = id;
    this.loadedCode = true;
  },

  mounted() {
    window.onCaptchaSuccess = (key) => {
      this.captchaKey = key
    };
  },

  computed: {
    logoHelper() {
      return logoHelper
    },

    getImageSize() {
      let screenWidth = screen.width;
      let width = 60;
      if (screenWidth > 576 && screenWidth <= 992) {
        width = 35;
      }

      return width;
    },

    getVideoWidth() {
      let screenWidth = screen.width;
      if (screenWidth >= 576 && screenWidth <= 992) {
        return 200
      }

      if (screenWidth <= 1400) {
        return 500
      }

      return screen.width / 2;
    },

    user: () => useUserStore().getUser
  },

  methods: {

    async loadRegistrationAccess() {
      try {
        const result = await axios.get(`/registration-access/info`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.registrationAccess = result.data
      } catch (error) {
        console.log(error)
      }
    },

    // Try to register the user in with the email, username
    // and password they provided.
    tryToRegisterIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.register();
    },

    register() {
      const json = JSON.stringify({
        email: this.account.email,
        password: this.account.password,
        firstName: this.account.firstName,
        lastName: this.account.lastName,
        referralUserId: this.account.referralUserId,
        captchaKey: this.captchaKey
      });

      axios.post(`/user/create`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(() => {
        this.submitted = false;
        window.location = '/login?registered=true'
      }).catch((error) => {
        this.submitted = false;
        this.isRegisterError = true;
        this.regError = errorCatcher.catchErrors(error);

        const ecatError = errorCatcher.getError(error)
        if (ecatError) {
          if (ecatError.type === "ReCaptchaErrors" && ecatError.code === 1) {
            window.grecaptcha.reset();
          }
        }
      })
    },

  }
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <FlagButton :drop-left="true"></FlagButton>
      <button @click="$store.dispatch('theme/switchTheme').then(() => $store.dispatch('theme/updateTheme'))"
              type="button" class="btn btn-sm px-3 font-size-24 header-item">
        <i :class="$store.getters['theme/isThemeDark'] ? 'ri ri-sun-line' : 'ri ri-moon-line'"
           style="color: #ffffff;"></i>
      </button>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <template v-if="registrationAccess && registrationAccess.restricted">
                    <div class="col-lg-12">
                      <div class="text-center">
                        <h3 class="text-danger">Rejestracja nowych kont jest obecnie niedostępna.</h3>
                        <h5>Najbliższa możliwość rejestracji dostępna:</h5>
                        <h4 class="text-success">{{ registrationAccess.date }}</h4>
                        <h4 class="text-success">{{ registrationAccess.from }} - {{ registrationAccess.to }}</h4>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="col-lg-9">
                      <div>
                        <div class="text-center">
                          <div>
                            <a href="/" class="logo">
                              <img :src="logoHelper.logo()"
                                   :height="getImageSize" alt="logo"/>
                            </a>
                          </div>

                          <h4 class="font-size-18 mt-4">{{ $t('landing-page-title') }}</h4>
                          <p class="text-muted">{{ $t('register.subtitle') }}</p>
                        </div>

                        <div class="p-2 mt-5">
                          <b-alert v-model="isRegisterError" class="mt-3 text-center\" variant="danger"> {{
                              $t(regError)
                            }}
                          </b-alert>

                          <form class="form-horizontal" @submit.prevent="tryToRegisterIn">
                            <div class="form-group auth-form-group-custom mb-2">
                              <i class="ri-mail-line auti-custom-input-icon"></i>
                              <label for="useremail">Email</label>
                              <input
                                  v-model="account.email"
                                  type="email"
                                  class="form-control"
                                  id="useremail"
                                  :placeholder="$t('message.type', { 'type': 'email'})"
                                  :class="{ 'is-invalid': submitted && $v.account.email.$error }"/>
                              <div v-if="submitted && $v.account.email.$error" class="invalid-feedback">
                                <span v-if="!$v.account.email.required">{{ $t('message.required') }}</span>
                                <span v-if="!$v.account.email.email">{{ $t('message.email-error') }}</span>
                              </div>
                            </div>

                            <div class="form-group auth-form-group-custom mb-2">
                              <i class="ri-lock-2-line auti-custom-input-icon"></i>
                              <label for="password">{{ $t('message.password') }}</label>
                              <input
                                  v-model="account.password"
                                  type="password"
                                  class="form-control"
                                  id="password"
                                  :placeholder="$t('message.type', { 'type': $t('message.password').toLowerCase()})"
                                  :class="{ 'is-invalid': submitted && $v.account.password.$error }"/>
                              <div v-if="submitted && !$v.account.password.required" class="invalid-feedback">
                                {{ $t('message.required') }}
                              </div>
                              <div v-if="submitted && !$v.account.password.minLength" class="invalid-feedback">
                                {{ $t('register.password-min-length') }}
                              </div>
                              <div v-if="submitted && !$v.account.password.containsUppercase" class="invalid-feedback">
                                {{ $t('register.password-uppercase') }}
                              </div>
                              <div v-if="submitted && !$v.account.password.containsLowercase" class="invalid-feedback">
                                {{ $t('register.password-lowercase') }}
                              </div>
                              <div v-if="submitted && !$v.account.password.containsNumber" class="invalid-feedback">
                                {{ $t('register.password-numeric') }}
                              </div>
                              <div v-if="submitted && !$v.account.password.containsSpecial" class="invalid-feedback">
                                {{ $t('register.password-special') }}
                              </div>
                            </div>

                            <div class="form-group auth-form-group-custom mb-2">
                              <i class="ri-lock-2-line auti-custom-input-icon"></i>
                              <label for="confirmPassword">{{ $t('register.repeat-password') }}</label>
                              <input
                                  v-model="account.confirmPassword"
                                  type="password"
                                  class="form-control"
                                  id="confirmPassword"
                                  :placeholder="$t('message.type', { 'type': $t('message.password').toLowerCase()})"
                                  :class="{ 'is-invalid': submitted && $v.account.confirmPassword.$error }"/>
                              <div v-if="submitted && !$v.account.confirmPassword.required" class="invalid-feedback">
                                {{ $t('message.required') }}
                              </div>
                              <div
                                  v-if="submitted && !$v.account.confirmPassword.sameAsPassword && $v.account.confirmPassword.required"
                                  class="invalid-feedback">{{ $t('register.samePassword') }}
                              </div>
                            </div>

                            <div class="form-group auth-form-group-custom mb-2">
                              <i class="ri-user-2-line auti-custom-input-icon"></i>
                              <label for="firstName">{{ $t('message.first-name') }}</label>
                              <input
                                  v-model="account.firstName"
                                  type="text"
                                  class="form-control"
                                  id="firstName"
                                  :placeholder="$t('message.type', { 'type': $t('message.first-name').toLowerCase()})"
                                  :class="{ 'is-invalid': submitted && $v.account.firstName.$error }"/>
                              <div v-if="submitted && !$v.account.firstName.required" class="invalid-feedback">
                                {{ $t('message.required') }}
                              </div>
                            </div>

                            <div class="form-group auth-form-group-custom mb-2">
                              <i class="ri-user-2-line auti-custom-input-icon"></i>
                              <label for="lastName">{{ $t('message.last-name') }}</label>
                              <input
                                  v-model="account.lastName"
                                  type="text"
                                  class="form-control"
                                  id="lastName"
                                  :placeholder="$t('message.type', { 'type': $t('message.last-name').toLowerCase()})"
                                  :class="{ 'is-invalid': submitted && $v.account.lastName.$error }"/>
                              <div v-if="submitted && !$v.account.lastName.required" class="invalid-feedback">
                                {{ $t('message.required') }}
                              </div>
                            </div>

                            <div class="form-group auth-form-group-custom mb-2" v-if="!loadedCode">
                              <i class="ri-code-box-line auti-custom-input-icon"></i>
                              <label for="referral-user-id">{{ $t('message.referral-user') }}</label>
                              <input v-model="account.referralUserId" type="text" class="form-control"
                                     id="referral-user-id"
                                     :placeholder="$t('message.type', { 'type': $t('message.referral-user').toLowerCase()})"
                                     :class="{ 'is-invalid': submitted && $v.account.referralUserId.$error }"/>
                              <div v-if="submitted && !$v.account.referralUserId.isUUID" class="invalid-feedback">
                                {{ $t('register.invalid-referral') }}
                              </div>
                            </div>

                            <div class="form-group auth-form-group-custom mb-2" v-if="$store.getters['parameters/getReCaptchaSiteKey'] && $store.getters['recaptcha/isScriptLoaded']">
                              <div id="recaptcha-main" class="g-recaptcha" :data-sitekey="$store.getters['parameters/getReCaptchaSiteKey']" data-callback="onCaptchaSuccess" />
                            </div>

                            <div class="text-center py-2">
                              <button
                                  class="btn btn-primary w-100 waves-effect waves-light" type="submit">
                                {{ $t('register.register') }}
                              </button>
                            </div>

                            <div class="mt-4 text-center">
                              <p class="mb-0">
                                {{ $t('register.accept-terms-1') }}
                                <a href="/rules" target="_blank" class="clickable-element text-primary">{{ $t('register.accept-terms-2') }}</a>
                                {{ $t('message.and') }}
                                <a href="/privacy-policy" target="_blank" class="clickable-element text-primary">{{ $t('register.accept-terms-3') }}</a>.
                              </p>
                            </div>
                          </form>
                        </div>

                        <div class="mt-2 text-center">
                          <p>
                            {{ $t('register.has-account') }}
                            <router-link
                                tag="a"
                                to="/login"
                                class="font-weight-medium text-primary"
                            >{{ $t('register.logIn') }}
                            </router-link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <information-panel-render/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>